/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import {graphql, useStaticQuery} from 'gatsby';

function SEO({description, lang, meta, title, ogImageUrl, ogImageTwitterUrl}) {
  const {site} = useStaticQuery(
    graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                }
            }
        }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;

  ogImageUrl = `https://stocksolo.com${ogImageUrl}?v=4`;
  ogImageTwitterUrl = `https://stocksolo.com${ogImageTwitterUrl}?v=4`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${site.siteMetadata.title} | %s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: ogImageUrl,
        },
        {
          property: `image`,
          content: ogImageUrl,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:site`,
          content: '@getredokun',
        },
        {
          name: `twitter:creator`,
          content: '@getredokun',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: ogImageTwitterUrl,
        },
      ].concat(meta)}
    >
      <script type="text/javascript">{`
        window.$crisp=[];window.CRISP_WEBSITE_ID="27947d64-30ab-472f-94f6-a34311ba8e45";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();
      `}</script>

      <div dangerouslySetInnerHTML={{
        __html: `
    <!-- TradeDoubler site verification 3149204 -->
  `,
      }}
      />

    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
