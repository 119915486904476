/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {graphql, useStaticQuery} from 'gatsby';

import './main.css';
import bgImg from '../images/bg-1.1.png';
import styles from './layout.module.css';

const Layout = ({children}) => {
  const [email, setEmail] = useState('');

  const data = useStaticQuery(graphql`
      query SiteTitleQuery {
          site {
              siteMetadata {
                  title
              }
          }
      }
  `);

  useEffect(() => {
    // Anti-Spam measure LOL
    setTimeout(() => setEmail('info@stocksolo.com'), 200);
  }, []);

  return (
    <>
      <div className={styles.main} style={{
        background: `url(${bgImg}) center center no-repeat`,
        backgroundSize: 'cover',
      }}>
        {/*<Header siteTitle={data.site.siteMetadata.title} />*/}
        <div className={`${styles.container} text-gray-100 font-sans`}>

          <main>{children}</main>

          <footer className={'text-center text-gray-400 mt-20'}>
            © {new Date().getFullYear()}, By
            {` `}
            <a
              href="https://redokun.com?utm_campaign=stocksolo&utm_medium=website">
              Redokun Srls
            </a>
            {' – '} Made with ❤️ in Italy
            <div className={'text-sm text-gray-500 mt-1'}>
              Via Schiavonesca Priula, 68/13 – Montebelluna, 31044 (TV) - Italy

              {email && <div>
                Support: {email}
              </div>}
            </div>

            <div className="my-16">
              <div className="mb-3">
                Brought to you by:
              </div>

              <div className={styles.allBadges}>
                <div className={styles.badgesContainer}>

                  <a href="https://twitter.com/ago_paul" target="_blank"
                     className={styles.badge}>
                    <img
                      src="https://www.gravatar.com/avatar/ba2f140d1ae0c8fa39dc743d3b30f2c6?d=mm&amp;s=150"
                      alt="ago_paul"
                      className={styles.badgeImg}/>


                    <div className={styles.handle}>
                        <span className={styles.handleContainer}>
                          @ago_paul
                        </span>
                    </div>
                  </a>

                </div>
                <div className={styles.badgesContainer}>

                  <a href="https://twitter.com/ste_berna" target="_blank"
                     className={styles.badge}>
                    <img
                      src="https://www.gravatar.com/avatar/5004623baa113bedf9213db0209adf23?d=mm&amp;s=150"
                      alt="ste_berna"
                      className={styles.badgeImg}/>

                    <div className={styles.handle}>
                      <span className={styles.handleContainer}>
                        @ste_berna
                      </span>
                    </div>
                  </a>

                </div>
              </div>
            </div>

          </footer>
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
